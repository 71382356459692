/* You can add global styles to this file, and also import other style files */

@import './theme.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.route-active {
  &.mat-button {
    text-decoration: underline;
  }

  &.mat-list-item {
    text-decoration: underline;
  }
}

.dialog-flex {
  > .mat-dialog-container {
    display: flex;
    flex-direction: column;

    > :first-child {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      > .mat-dialog-content {
        max-height: unset;
        flex-grow: 1;

        .mat-tab-body-content {
          overflow: hidden;
        }
      }
    }
  }
}

.w-100 {
  width: 100%;
}
